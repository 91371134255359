<template>
  <div>
    <m-bread-crumb/>

<section class="blog spad">
        <div class="container">
            <div class="row">
                
                <div class="col-lg-9">
                    <div class="row">
                    <h5>Conheça as soluções de crédito que o AC Microbanco lhe oferece para realizar os seus projectos.
                    Saiba mais e descubra a melhor opção para si.
                    </h5>
                    <br>
                    </div>
                </div> 
                
    <div class="col-lg-9">
            <div class="row">
             
                          
                        
                        
                <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="blog__item">
                                <!--<div class="blog__item__pic set-bg" data-setbg="img/blog/blog-1.jpg">-->
                                
                                <div class="box21">
                                <img src="img/blog/blog-15.jpg" alt="" style="border: 5px solid white;">
                                    <div class="box-content">
                                        <h4 class="title" style="color:white;">Crédito ao Investimento</h4>
                                        
                                        <div class="card card-body">
                                        <p style="text-align: justify"><i class="fa fa-chevron-circle-right" style="color:red;"></i>
                                        <router-link :to="{name: 'CreditoPME'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito PME - Rural Comércio</router-link><br>
                                        
                                      
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> 

                                  <router-link :to="{name: 'CreditoRural'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito Rural Agropecuaria</router-link><br>

                                    </p>
                                      </div>
                                        <a class="read-more" href="empresas.html#credito_investimento">Saber mais</a>
                                    </div>
                                </div>
                                
                            
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="blog__item">
                                <!--<div class="blog__item__pic set-bg" data-setbg="img/blog/blog-1.jpg">-->
                                
                                <div class="box21">
                                <img src="img/blog/blog-16.jpg" alt="" style="border: 5px solid white;">
                                    <div class="box-content">
                                        <h4 class="title" style="color:white;">Desconto de Livranças</h4>
                                        
                                        <div class="card card-body">
                                        <p style="text-align: justify"><i class="fa fa-chevron-circle-right" style="color:red;"></i>
                                      <router-link :to="{name: 'DescontoLivranca'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Desconto de Livranças</router-link><br>
		                              
                                    </p>
                                      </div>
                                        <a class="read-more" href="empresas.html#desconto_livranca">Saber mais</a>
                                    </div>
                                </div>
                                
                            
                        </div>
                    </div>
                        
                    <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="blog__item">
                                <!--<div class="blog__item__pic set-bg" data-setbg="img/blog/blog-1.jpg">-->
                                
                                <div class="box21">
                                <img src="img/blog/blog-17.jpg" alt="" style="border: 5px solid white;">
                                    <div class="box-content">
                                        <h4 class="title" style="color:white;">Garantias Bancárias</h4>
                                        
                                        <div class="card card-body">
                                        <p style="text-align: justify"><i class="fa fa-chevron-circle-right" style="color:red;"></i>

                                        <router-link :to="{name: 'GarantiasBancarias'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Garantias Bancárias</router-link><br>

		                              
                                    </p>
                                      </div>
                                        <a class="read-more" href="empresas.html#garantia_bancaria">Saber mais</a>
                                    </div>
                                </div>
                                
                            
                        </div>
                    </div>
                
                <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="blog__item">
                                <!--<div class="blog__item__pic set-bg" data-setbg="img/blog/blog-1.jpg">-->
                                
                                <div class="box21">
                                <img src="img/blog/blog-18.png" alt="" style="border: 5px solid white;">
                                    <div class="box-content">
                                        <h4 class="title" style="color:white;"></h4>
                                        
                                        <div class="card card-body">
                                        <p style="text-align: justify"><i class="fa fa-chevron-circle-right" style="color:red;"></i>
                                        <a href="garantiabancaria.html" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">
                                           </a><br>
		                              
                                    </p>
                                      </div>
                                        <a class="read-more" href="empresas.html#garantia_bancaria">Saber mais</a>
                                    </div>
                                </div>
                                
                            
                        </div>
                    </div>
  
                        
                    </div>            
   
                 </div>   
               
                <div class="col-lg-3 col-md-6 col-sm-9">
                    <div class="blog__sidebar">
                        <form action="#" class="blog__sidebar__search">
                            <input type="text" placeholder="Procurar...">
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                        
                        <div class="blog__sidebar__categories">
                            <h4>Créditos</h4>
                            <ul>
                               <li><router-link :to="{name: 'Particulares'}">Particulares</router-link></li>
                              <li><router-link :to="{name: 'Empresas'}">Empresas</router-link></li> 
                            
                            </ul>
                        </div>
                        

                    </div>
                </div>

        
            
        </div>
        
        </div>
    </section>
    <!-- Blog Section End -->
    
<section class="about spad" id="credito_investimento">
        <div class="container">
            <div class="row">

<div class="col-lg-6 col-md-6">
    <div class="contact__text">
            <div class="section-title">
                            <h3>Crédito ao <b>Investimento</b></h3>
                            <h2 style="color: red">Estamos consigo na Agropecuária.</h2>
                
                        <p style="text-align:justify">São operações de crédito de médio e longo prazo (superiores a 1 ano) com total flexibilidade no prazo, montante, e forma de reembolso.<br>
                        <br>
                        O seu negócio só precisa da oportunidade certa para crescer e de contar com as soluções de financiamento mais adequadas. 
                        </p>
            </div>
    </div>
</div>
<div class="col-lg-6 col-md-6">
     <div class="car__details__pic">
          <div class="car__details__pic__large">
            <img class="car-big-img" src="img/cars/details/cd-19.jpg" alt="">
           </div>
                        
     </div>
</div>
                
<div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Destina-se a financiar, com flexibilidade, projectos de investimento, de aquisição e manutenção de máquinas e equipamentos agrícolas (que serão usados no ciclo produtivo da sua empresa).
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
             Particulares e Empresas.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Versatilidade - Investimento que se adequa ao seu projecto e a sua capacidade financeira; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Liberta capitais próprios da Empresa para serem utilizados em outras necessidades.<br /> 

                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Despesas e comissões
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            As despesas e comissões decorrentes do processo de financiamento serão debitadas automaticamente na conta do mutuário, de acordo com o plano financeiro acordado.<br />

            Em caso de liquidação antecipada, total ou parcial, do capital em dívida fica sujeito a penalização.        
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Características
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p>
		                          <i class="fa fa-chevron-circle-right" style="color:red;"></i> Todas as operações estão sujeitas aos critérios da análise de risco/garantias associadas.<br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Modalidade de empréstimo de curto e médio prazo (até 3 anos);<br>
		                              
                    </p>
                </ul>
        </div>
     
        
    </div>
  </div>
     
<div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          Garantias: Garantias reais (hipoteca), entre outras
        </button>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Seguros<br>
            - Seguro multirrisco: do valor do imóvel objecto de Financiamento ou hipotecado, tendo o AC MicroBanco como credor hipotecário;<br>
            - Seguro de vida (capital decrescente)<br>
            <br />      
        </ul>
      </div>
    </div>
</div>
     
<div class="card">
    <div class="card-header" id="headingEight">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
          Documentação
        </button>
      </h5>
    </div>
    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            O negócio deve existir pelo menos há seis meses<br>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Fotocopia do BI, Cartão de contribuinte e licenças da empresa reconhecida<br>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Prova de pagamento do imposto pela empresa<br>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Estatutos da empresa<br>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Extracto bancário dos últimos seis meses<br>
<br />      
        </ul>
      </div>
    </div>
</div>
     
    
</div>               

                

            </div>
        </div>
    
</section>
    
<section class="about spad" id="desconto_livranca">
        <div class="container">
            <div class="row">
                
<div class="col-lg-6 col-md-6">
    <div class="contact__text">
            <div class="section-title">
                            <h3>Desconto de <b>Livranças</b></h3>
                            <h2 style="color: red" >Dê crédito ao crescimento da sua empresa.</h2>
                        <p style="text-align:justify">A Solução tradicional para financiamento pontual de tesouraria de Curto Prazo para a sua Empresa.
                        </p>
            </div>
    </div>
</div>
<div class="col-lg-6 col-md-6">
     <div class="car__details__pic">
          <div class="car__details__pic__large">
            <img class="car-big-img" src="img/cars/details/cd-20.jpg" alt="">
           </div>
                        
     </div>
</div>
                    
                

<div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Ideal para Empresas com necessidades financeiras pontuais de tesouraria de curto prazo.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Para clientes particulares e empresas.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Simplicidade e rapidez na obtenção de crédito; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Produto simples, em que os juros são antecipadamente pagos.<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Possibilidade de reforma; <br />
             <br />

                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Despesas e comissões
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            As despesas e comissões decorrentes do processo de financiamento serão debitadas automaticamente na conta do mutuário, de acordo com o plano financeiro acordado.<br />

            Em caso de liquidação antecipada, total ou parcial, do capital em dívida fica sujeito a penalização.        
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Características
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>	Montantes: Conforme aprovação de crédito, mediante avaliação do risco da operação;<br>
		          <i class="fa fa-chevron-circle-right" style="color:red;"></i> Prazo: Mínimo de 1 mês e máximo de 12 meses<br>
		          <i class="fa fa-chevron-circle-right" style="color:red;"></i> Garantias: As garantias reais, entre outras, são estabelecidas no âmbito da análise de risco de crédito.<br>
                    </p>
                </ul>
        </div>
     
        
    </div>
  </div>
    
</div>      


        </div>
    </div>
</section>
    
<section class="about spad" id="garantia_bancaria">
        <div class="container">
            <div class="row">
                
<div class="col-lg-6 col-md-6">
    <div class="contact__text">
            <div class="section-title">
                            <h3><b>Garantias Bancárias</b></h3>
                            <h2 style="color: red" >Solução Garantias Bancárias</h2>
                        <p style="text-align:justify">São operações de crédito de curto prazo (ate 1 ano) com flexibilidade no prazo, montante, e forma de reembolso.
                        </p>
            </div>
    </div>
</div>
<div class="col-lg-6 col-md-6">
     <div class="car__details__pic">
          <div class="car__details__pic__large">
            <img class="car-big-img" src="img/cars/details/cd-21.jpg" alt="">
           </div>
                        
     </div>
</div>
                    
 <div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Tem por finalidade, garantir a execução de obrigações assumidas pelo Cliente (Ordenador) perante terceiros (Beneficiários).
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
             Esta solução esta direcionada para Empresas em Nome Individual (ENI's) e Colectivo.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Simplicidade e rapidez na obtenção de crédito; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Produto simples, em que os juros são antecipadamente pagos.<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Possibilidade de reforma; <br />
             <br />

                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Despesas e comissões
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            As despesas e comissões decorrentes do processo de financiamento serão debitadas automaticamente na conta do mutuário, de acordo com o plano financeiro acordado.<br />

            Em caso de liquidação antecipada, total ou parcial, do capital em dívida fica sujeito a penalização.        
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Características
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><b>Garantias Financeiras</b></p><br>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>	•	Alfandegárias - para caucionamento de desalfandegamento de       mercadorias;
                        Caucionamento de armazém afiançado;
                        <br>
		          <i class="fa fa-chevron-circle-right" style="color:red;"></i> •	Aquisição de mercadorias ou bens a prazo.<br>
                    </p>
                </ul>
            
                <ul>
                    <p><b>Garantias Técnicas</b></p><br>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>	•	Participação em Concurso Público/Privado;
                        
                        <br>
		          <i class="fa fa-chevron-circle-right" style="color:red;"></i> •	Aquisição de mercadorias ou bens a prazo.<br>
                    </p>
                </ul>
        </div>
     
        
    </div>
  </div>
     
<div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          Limite
        </button>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Determinado em função da análise de risco do cliente, finalidade de operação e contra garantias apresentadas.<br />      
        </ul>
      </div>
    </div>
</div>
     
<div class="card">
    <div class="card-header" id="headingSeven">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
          Prazo
        </button>
      </h5>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Máximo 12 Meses (1 Ano).<br />      
        </ul>
      </div>
    </div>
</div>
     
<div class="card">
    <div class="card-header" id="headingEigth">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEigth" aria-expanded="false" aria-controls="collapseFour">
          Requisitos e obrigações do proponente
        </button>
      </h5>
    </div>
    <div id="collapseEigth" class="collapse" aria-labelledby="headingEigth" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Licenciado para o exercício da actividade comercial (Alvará);<br />
             <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Obrigações fiscais e contribuições da Segurança Social em dia;<br /> 
             <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Apresentar Caderno de encargos (em caso de garantia para concurso);<br />
             <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Apresentar o Contrato com o beneficiário (que origina a Garantia);<br /> 
             <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Comprometer-se a cumprir com o estabelecido no Contrato de Emissão da Garantia Bancária.
            <br />      
        </ul>
      </div>
    </div>
</div>
    
</div>               


        </div>
    </div>
</section>
    
    

    
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Empresas",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>